@use './variables' as *;
@use './functions' as *;
@use './mixins' as *;

/* Global box-sizing for consistent padding and border behavior */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Overlay styles */
.loading-overlay {
  position: absolute; /* Position absolutely within the relative container */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Darken the background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Adjust z-index as needed */
}

.filters-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.clickable-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 10px; // Adds some padding for better clickability and appearance
  border-radius: 4px; // Softens the edges for a modern look
  transition: background-color 0.3s ease, transform 0.3s ease; // Smooth transition for hover effects
  font-weight: bold; // Bold text for emphasis

  &:hover {
    background-color: $darkgray-transparent; // Light grey background on hover to indicate it's clickable
    transform: translateY(-1px); // Slight raise effect to simulate a button press
    text-decoration: underline; // Underline to indicate it's clickable
  }

  svg { // Directly style the SVG icons from Material-UI if needed
    fill: currentColor; // Ensures the icon color matches the text color
    margin-left: 5px; // Adds some space between the text and the icon
  }
}

.filter-container {
  .filter-category {
    h4 {
      text-align: center;
    }
    .filter-options {
      .filter-option {
        display: flex;
        align-items: center;
        margin: 10px 0;
        input[type="checkbox"] {
          margin-right: 10px;
        }
        &:hover {
          background-color: $lightgray-transparent; // Light grey background on hover for visual feedback
          cursor: pointer; // Change cursor to pointer on hover
        }
      }
    }
    .filter-options-inline {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; // Adjust based on your design
      .filter-option {
        margin: 5px 2px; // Gives space around each option for distinction
        input[type="checkbox"] {
          margin-right: 5px;
        }
      }
    }
    .year-option {
      padding: 0 10px;
      border-radius: 5px;
      &:hover {
        background-color: $lightgray-transparent; // Light grey background on hover for visual feedback
        cursor: pointer; // Change cursor to pointer on hover
      }
    }
  }
}

.arrow-up, .arrow-down {
  cursor: pointer;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.arrow-down {
  border-top: 5px solid black; // Adjust color as needed
}

.arrow-up {
  transform: rotate(180deg);
}


/* Base container styles */
.text-search-pwa-container {
  background-color: $white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  position: relative; /* Set position to relative */
}

/* Content wrapper styles */
.text-search-pwa-content {
  position: relative;
  width: 100%;
  overflow-y: auto;
  background-color: $white;
  border-radius: $grid-gap-phone * 0.4;
  padding: 1rem;
  align-self: center;
}

/* Search bar styles */
.search-bar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-input-container {
  width: 80%;
  margin-bottom: 0.5rem;
}

.search-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.search-info.visible {
  opacity: 1;
}

/* Add this to make the specific text italic */
.italic-text {
  font-style: italic;
}

.search-input {
  font-size: 1.25rem;
  padding: 0.75rem 1rem;
  width: 100%;
  border-radius: $grid-gap-phone * 0.4 $grid-gap-phone * 0.4 0px 0px; /* Rounded top corners only */
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-button {
  align-self: flex-start;
  all: unset;
  min-width: $grid-gap-phone;
  height: 1.5*$grid-gap-phone;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: $grid-gap-phone * 0.2 $grid-gap-phone * 0.4;
  color: $darkgray;
  background-color: $lightgray;
  transition: color $transition-fast $transition-easing, background-color $transition-medium $transition-easing;
  border-radius: $grid-gap-phone * 0.4;

  > div {
    color: $darkgray;
  }

  &.action {
    color: $white;
    background-color: $orange;

    > div {
      color: $white;
    }
  }

  &.low-action {
    color: $white;
    background-color: $orange-pale;

    > div {
      color: $white;
    }
  }

  &.teaser {
    color: $darkgray;
    background-color: $lightgray;

    > div {
      color: $darkgray;
    }
  }

  &.disabled, &.success, &.error {
    user-select: none;
    pointer-events: none;
    background-color: $lightgray;
  }

  &.success {
    color: $green;
    background-color: $green-pale;
  }

  &.error {
    color: $orange;
    background-color: $orange-pale;
  }

  @include interaction-hover() {
    color: $lightgray;
    background-color: $darkgray;
    cursor: pointer;

    &.action {
      color: $orange-pale;
      background-color: $orange;
    }
  }
}
.search-button:hover {
  background-color: #ddd;
}
.filters-button-container {
  display: flex;
  justify-content: center;
}

.apply-filters-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: $grid-gap-phone * 0.4;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.apply-filters-button:hover {
  background-color: #ddd;
  color:  #000;
}

/* Lower sections wrapper styles */
.lower-sections {
  display: none; /* Initially hidden */
  flex-direction: row; /* Horizontal layout by default */
  gap: 10px;
  margin-top: 2rem;
}

/* Filter section styles for desktop*/
.filter-graph-section {
  border: 1px solid $cadet;
  padding: 16px;
  border-radius: 10px;
  background-color: white;  width: 25%;
  flex-direction: column;
  gap: 1rem;
  height: fit-content;
}

.active-filters-section {
  margin-bottom: 20px;

  .active-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .active-filter {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 10px;

    &.pending-filter {
      background-color: $lightgray-transparent; // Light yellow, for example, to indicate pending
      color: $cadet-pale;
      &:hover {
        color: $darkgray; /* Change text color on hover, choose color as per your design */
      }
    }

    &.applied-filter {
      background-color: $darkgray; // Light green, for example, to indicate applied
      &:hover {
        color: $white; /* Change text color on hover, choose color as per your design */
      }
    }

    .remove-filter {
      cursor: pointer;
      background: none;
      border: none;
    }
  }
}

.filters-notification {
  padding: 10px;
  background-color: $orange-pale;
  color: $cadet; /* Dark red text */
  text-align: center;
}

.filter-section {
  padding: 0;
  max-width: 100%;
  position: relative;
}
.graph-section{
  position: relative;
  height: 20vh;
  border: 1px dashed #ccc;
  border-radius: 2px;
}

.graph-section-dialog{
  position: relative;
  height: 60vh;
  border: 2px solid #ccc;
  border-radius: 10px;
}

/* Adjusted Results section styles */
.results-section {
  flex: 1;
  padding: 16px;
  border-radius: 10px;
  background-color: white;  border: 1px solid $cadet;
  display: flex;
  flex-direction: column;
}

.controls-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; /* Space before results listing */
}

.start-chat-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}
.start-chat-button:hover {
  background-color: #ddd;
}

.document-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  border-bottom: 1px solid #ddd; /* Separate header from content */
}

.similarity-score {
  font-size: 0.9rem; /* Adjust font size */
  color: #666; /* Subtle text color */
  padding: 5px 10px; /* Padding for better spacing */
  border-radius: 5px; /* Rounded corners */
  margin: 0 10px; /* Margin for separation */
  flex-shrink: 0; /* Prevent the element from shrinking */
  text-align: center; /* Center the text */
}


.document-title {
  color: #333; /* Dark color for readability */
  font-size: 0.9rem; /* Adjust font size */
  flex-grow: 1; /* Allow the title to take up space */
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between checkbox and label */
}

.checkbox-container label {
  font-size: 0.9rem; /* Adjust font size */
  color: #333; /* Subtle text color */
}


.download-button {
  margin-left: 10px; /* Space before the download button */
}

/* Rest of the styles remain the same */

.info-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd; /* Add a subtle line to separate the info bar */
  background-color: transparent; /* Remove background color */
}
.info-icon {
  width: 24px; /* Example size, adjust as needed */
  height: 24px; /* Example size, adjust as needed */
  vertical-align: middle;
}

.info-bar span {
  display: flex;
  align-items: center;
  font-size: 0.8rem; /* Reduced font size for a finer look */
  color: #666; /* Subtle text color */
}

.info-icon {
  margin-right: 5px;
  font-size: 1rem; /* Adjust icon size */
  color: $darkgray; /* Icon color */
}

/* Updated Style for pagination */
.pagination {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  list-style: none;
  margin-left: 1rem; /* Align to the left */
}

.pagination a {
  display: flex; /* Flex display for centering content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  color: $cadet;
  padding: 5px; /* Adjust padding as needed */
  text-decoration: none;
  border: 1px solid $cadet-pale;
  margin: 0 2px; /* Reduced margin */
  width: 30px; /* Fixed width for consistency */
  height: 30px; /* Fixed height for vertical centering */
  font-size: 0.85rem; /* Smaller font size for a more elegant look */
  border-radius: 4px; /* Rounded corners for elegance */
}

.pagination a.active {
  background-color: $orange;
  color: white;
  border: 1px solid $cadet;
}

.pagination a:hover:not(.active) {
  background-color: $orange-pale;
}

/* Animations and transitions */
@keyframes slideUp {
  to { margin-top: 0; }
}

@keyframes fadeIn {
  to { opacity: 1; }
}

.search-bar-wrapper.search-active {
  animation: slideUp 0.5s ease-out forwards;
}

.text-search-pwa-content.search-active {
  animation: slideUp 0.5s ease-out forwards;
}

.text-search-pwa-content.search-active .lower-sections {
  display: flex;
  animation: fadeIn 0.5s ease-out;
}

/* Mobile specific styles */
@media (max-width: 768px) {
  .search-bar-wrapper {
    flex-direction: column;
    align-items: stretch;
  }

  .search-input {
    order: 1;
    margin-bottom: 0.5rem;
  }

  .buttons-container {
    order: 2;
    display: flex;
    justify-content: center; /* Centers the buttons horizontally */
    width: 100%; /* Ensures the container takes full width */
    padding: 0.5rem; /* Adds some padding around the buttons */
    gap: 0.5rem; /* Provides some space between buttons */
  }

  .filter-toggle-btn {
    display: none; /* Shown via JavaScript */
    order: -1; /* Places filter button before the input */
  }

  .search-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
  }

  .search-active .filter-toggle-btn {
    display: block; /* Shown when search is active */
  }

  .filter-section {
    flex: 1; /* Allow the filter section to fill the available space */
    max-width: none; /* Remove max-width restriction */
    width: 100%; /* Full width */
  }

  .results-section {
    width: 100%;
  }

  .text-search-pwa-container,
  .text-search-pwa-content {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
  }
}

.mobileFilterModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.mobileFilterModal > div {
  background: #fff;
  padding: 1rem;
  border-radius: 5px;
}

.probability-bar-wrapper {
  position: absolute;
  top: 0; /* Align to the top of the result item */
  left: 0;
  right: 0;
  height: 8px; /* Height of the bar */
  border-radius: 8px 8px 0 0; /* Rounded top corners */
  overflow: hidden; /* Ensures rounded corners are maintained */
}

.probability-bar {
  display: flex;
  height: 100%;
}

.probability-segment {
  height: 100%;
  transition: transform 0.3s ease;
}

.probability-segment:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
  cursor: pointer;
}

.result-item {
  background-color: $lightgray-transparent; /* Light grey background */
  margin-bottom: 1rem; /* Space between items */
  border-radius: 10px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  position: relative; /* Needed for absolute positioning of the bar */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure space between content and info bar */
  height: auto; /* Adjust height as needed */
  padding: 1rem 1rem 0;
}

.result-item:hover {
  background-color: $lightgray; /* Slightly darker background on hover */
}

.result-item h4 {
  margin: 0 0 0.5rem 0; /* Margin for the heading */
  color: #333; /* Darker text color for contrast */
  font-size: 1.1rem; /* Slightly larger font size */
}

.chatbot-dialog {
  width: 80vw !important; /* Increase the specificity and add !important */
  height: 80vh !important; /* Increase the specificity and add !important */
  /* Center the dialog */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.chatbot-title {
  background-color: #4A90E2; /* Stylish header color */
  color: white;
  font-size: 1.2rem;
  padding: 10px 15px;
}

.chatbot-content {
  background-color: #f4f4f4; /* Light background for the content area */
  padding: 20px;
  min-height: 500px; /* Increase the height */
}

/* Styles for the chat history */
.chat-history {
  overflow-y: auto;
  margin-bottom: 15px;
  padding-right: 10px; /* Allow space for scrollbar */
}

/* Styles for individual chat messages */
.chat-message {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.chat-message-user {
  text-align: right;
  word-break: break-word;
}

.chat-message-bot {
  text-align: left;
  word-break: break-word;
}

/* Styles for the chat input area */
.chat-input-container {
  display: flex;
  align-items: center;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
}

.chat-input-field {
  flex: 1; /* Make input field flexible in size */
  margin-right: 10px;
}

.chat-send-button {
  padding: 10px;
  margin: 0 5px;
  background-color: #4A90E2;
  color: white;
}

/* Additional styling for the Send icon button */
.chat-send-button:hover {
  background-color: #3d7ab9;
}

.querySnippet {
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px; // Ensure space for bullet point

  &:hover {
    background-color: #f0f0f0; // Light background on hover for visual feedback
  }

  p {
    margin: 0; // Remove default paragraph margin
  }

  .bulletPoint {
    position: absolute;
    left: 0;
    color: #333; // Dark gray for the bullet point
  }
}
