@use '../../../styles/variables' as *;
@use '../../../styles/functions' as *;
@use '../../../styles/mixins' as *;

.pseudo-lab-background {
  background-color: $white; // or the specific color you want for the background
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.busy-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $darkgray-transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $orange;
  font-size: 2rem;
  z-index: 1000;
}

.pseudo-lab {
  padding: 20px;
  text-align: center;
  background-color: $white;
  width: 90%;
  max-width: 1400px;
  margin: 0 auto; /* This centers the container horizontally */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto; /* Ensure the inner content is scrollable if needed */

  .custom-tabs {
    display: flex;
    justify-content: left;
    margin: 20px 10px;

    button {
      margin: 0 10px;
    }
  }

  .input-section, .output-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 20px;
    min-height: 400px;
    max-height: 40vh;
    flex: 1;
    border: 1px solid $cadet;  /* Set border color to light gray */

    &:hover {
      border: 1px solid $orange;  /* Add a border on hover */
    }

    .textarea-container {
      width: 100%;
      height: calc(100% - 40px);  // Adjust height to fit the container
      overflow-y: auto;  // Scrollbar management moved here
    }

    .text-input {
      flex-grow: 1;
      border: none;
      background: none;
      font-size: 16px;
      resize: none;
      outline: none;
      width: 100%;
      height: 100%;  // Fill the container but respect container's padding
      padding: 10px;  // Padding for text area inside the container
    }

    .left-side-icon {
      position: absolute;
      bottom: 10px;
      left: 10px;
      cursor: pointer;
      color: $cadet;  /* Set icon color to light gray */
      font-size: 30px;  /* Adjust font size to make lines finer */
      font-weight: 100;  /* Set font weight to lighter */
      &:hover {
        color: $orange;  /* Change icon color on hover */
      }
    }
    .right-side-icon {
      position: absolute;
      bottom: 10px;
      right: 10px;  // Position the copy icon on the right side
      cursor: pointer;
      color: $cadet;  // Set icon color to light gray
      font-size: 30px;  // Adjust font size to make lines finer
      font-weight: 100;  // Set font weight to lighter
      &:hover {
        color: $orange;  // Change icon color on hover
      }
    }
  }

  .text-field-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;

    .button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 10px;
      padding-right: 10px;

      .token-info {
        position: absolute;
        display: flex;
        align-items: center;
        margin-top: calc(2*$grid-gap-phone + 10px);
        font-size: 14px;

        img {
          margin-left: 10px;
          width: 24px;
          height: 24px;
        }

        .lock-icon {
          margin-left: 10px;
          color: $orange;
          font-size: 24px;
        }
      }
    }
  }

  .input-section {
    background-color: white;  /* Set background to white */
  }

  .output-section {
    background-color: $lightgray;  /* Set background to light gray */
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }

  .tab-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .content-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin: 20px 0;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  }
}

.anonymize-tab {
  width: 100%;
  text-align: left;


  .settings-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 20px 0;
    padding-left: 20px;
  }

  .settings-dropdown {
    font-size: 16px;
  }

  .settings-icon {
    margin-left: 10px;
    font-size: 24px;
    color: gray; /* Adjust color as needed */
  }

  .content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}
.settings-menu-paper {
  margin-left: 20px; // Adjust to align with the text field
  // Additional styles to ensure alignment with the settings-container
}
.MuiMenu-root {
  margin-top: 10px;
//  set hover color
  .MuiMenuItem-root {
    &:hover {
      background-color: $lightgray-transparent;
    }
    &.Mui-selected {
      background-color: $orange;
      color: $white;
    }
  }
}

