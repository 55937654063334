@use '../../styles/variables' as *;
@use '../../styles/functions' as *;
@use '../../styles/mixins' as *;

.team {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: $grid-gap-phone $grid-gap-phone;
  align-items: center; // Aligns items on the cross-axis
  align-content: center; // Centers the grid items as a block, useful if the grid container has extra space in the column direction
  text-align: center;
  background-color: white;
  // enable auto scroll for the grid container
  overflow-y: auto;

  @include breakpoint-small {
    grid-template-columns: repeat($grid-columns-desktop, 1fr);
    gap: $grid-gap-desktop $grid-gap-desktop;
  }

  h2, h2 + p, h6 {
    grid-column: 1 / calc(12 + 1);

    @include breakpoint-small {
      grid-column: 4 / calc($grid-columns-desktop - 2);
    }
  }

  h5 {
    grid-column: 1 / calc(12 + 1);
    margin-top: $grid-gap-phone;
    color: $darkgray;

    @include breakpoint-small {
      grid-column: 3 / calc($grid-columns-desktop - 1);
    }
  }

  p {
    margin-bottom: $grid-gap-phone;
  }

  > div {
    align-self: start;

    &:nth-of-type(1) {
      grid-column: 1 / 13;

      @include breakpoint-small {
        grid-column: 3 / calc($grid-columns-desktop - 4);
      }
    }

    &:nth-of-type(2) {
      grid-column: 1 / 13;

      @include breakpoint-small {
        grid-column: 6 / calc($grid-columns-desktop - 1);
      }
    }
  }
  .customCardContent {
    background-color: $white;
    padding: $grid-gap-phone; // Reduced padding for mobile
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: $grid-gap-phone;
    h6 {
      color: $orange;
    }

    @include breakpoint-small {
      padding: $grid-gap-desktop; // Larger padding for desktop
    }
  }
}